import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f99ae54a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-new-level_row" }
const _hoisted_2 = { class: "home-new-level_column" }
const _hoisted_3 = { class: "home-new-level_title" }
const _hoisted_4 = {
  key: 0,
  class: "home-new-level_description"
}
const _hoisted_5 = {
  key: 0,
  class: "home-new-level_column"
}
const _hoisted_6 = { class: "home-new-level_banner banner" }
const _hoisted_7 = ["data-analytics-element-target", "data-analytics-banner-id", "href"]
const _hoisted_8 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_block = _resolveComponent("home-block")!

  return (_ctx.banner)
    ? (_openBlock(), _createBlock(_component_home_block, {
        key: 0,
        class: "g-home-block home-new-level",
        "show-header": false
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('advancedLevel')), 1),
              (_ctx.banner.text)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.banner.text), 1))
                : _createCommentVNode("", true),
              (_ctx.banner.text)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "home-new-level_more",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBannerClick && _ctx.onBannerClick(...args)))
                  }, _toDisplayString(_ctx.$t('More')), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.image)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("a", {
                      class: "banner_link",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBannerClick && _ctx.onBannerClick(...args))),
                      ref: "refBanner",
                      "data-cy": "banner",
                      "data-analytics": "",
                      "data-analytics-element-type": "banner",
                      "data-analytics-element-target": _ctx.url,
                      "data-analytics-banner-id": _ctx.banner.id,
                      href: _ctx.url,
                      target: "_blank"
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.image,
                        alt: _ctx.banner.title,
                        class: "banner_image",
                        onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBannerShow && _ctx.onBannerShow(...args))),
                        loading: "lazy"
                      }, null, 40, _hoisted_8)
                    ], 8, _hoisted_7)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}